.home-page{

    .text-zone{
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 40%;
        max-height: 90%;
    }

    h1{
        color: #fff;
        font-size: 53px;
        margin: 0;
        font-family: 'Coolvetica';
        font-weight: 400;

        &::before{
            content: '<h1>';
            font-family: 'La Belle Aurore';
            color: #ffd700;
            font-size: 18px;
            position: absolute;
            margin-top: -40px;
            left: 15px;
            opacity: 0.6;
        }

        &::after{
            content: '</h1>';
            font-family: 'La Belle Aurore';
            color: #ffd700;
            font-size: 18px;
            position: absolute;
            margin-top: 18px;
            margin-left: 20px;
            animation: fadeIn 1s 1.7s backwards;
            opacity: 0.6;
        }
        img{
            width: 60px;
            margin-left: 20px ;
            opacity: 0;
            height: auto;
            animation: rotateIn 1s linear both;
            animation-delay: 1.4s;
        }
    }

    h2{
        color: #8d8d8d;
        margin-top: 20px;
        font-weight: 400;
        font-size: 11px;
        font-family: sans-serif;
        letter-spacing: 3px;
        animation: fadeIn 1s 1.8s backwards;
    }

    .flat-button{
        color: #ffd700;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #ffd700;
        margin-top: 25px;
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;

        &:hover{
            background: #ffd700;
            color: #333;
        }
    }
}


.profile-pic-container {
    display: flex;
  justify-content: center;
  align-items: center;
  margin: 150px 0 0 600px;
}

.profile-pic{
    position: relative;
    width: 350px;
    height: 350px;
    background: rgba(0,0,0,0.5);
    border-radius: 50%;
    overflow: hidden;
}
.profile-pic::before{
    content: '';
    position: absolute;
    inset: -20px 140px;
    background: linear-gradient(315deg,#00ccff,#d400d4);
    transition: 0.5s;
    animation: animate 4s linear infinite;
}
.profile-pic:hover::before{
    inset: -20px 0px;
}

@keyframes animate {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}

.profile-pic::after{
    content: '';
    position: absolute;
    inset: 6px;
    background: #141414;
    border-radius: 50%;
    z-index: 1;
}

.content{
    position: absolute;
    inset: 30px;
    border: 6px solid #ffd700;
    z-index: 3;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.content img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.5s;
    pointer-events: none;
    z-index: 3;
}

.profile-pic:hover .content img{
    opacity: 0;

}

.content h2{
    position: relative;
    font-size: 1.5em;
    text-align: center;
    color: #fff;
    font-weight: 300;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-family: 'Coolvetica';
}

.content h2 span{
    font-weight: 300;
    font-size: 0.75em;
}

.content a{
    position: relative;
    margin-top: 10px;
    padding: 10px 20px;
    background: #fff;
    color: #141414;
    border-radius: 25px;
    font-weight: 500;
    font-size: 1.25em;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    text-decoration: none;
    transition: 0.5s;
}

.content a:hover{
    letter-spacing: 0.2em;
    background-color: #ffd700;
}

.home-page-mobile {
    display: flex;
    flex-direction: column;
}
.mobile-socialmedia{
    display: none;
}


@media screen and (max-width: 1200px) {
    .tags,
    .home-page h1:before,
    .home-page h1:after {
      display: none;
    }


  
    .home-page .text-zone {
      position: initial;
      width: 100%;
      transform: none;
      padding: 0 25px 0 25px;
      box-sizing: border-box;

      h1{
        font-size: 32px !important;
    }
    }
  
    .home-page .flat-button {
      float: none;
      display: block;
      margin: 20px auto 0 auto;
      width: 124px;
    }
  
    .logo-container {
      position: relative;
      width: 100px;
      height: auto;
      top: 50px;
      right: 0;
      box-sizing: border-box;
      margin: auto;
      left: 0;
    }
  
    .logo-container svg {
      position: absolute;
      top: auto;
      right: auto;
      bottom: auto;
      left: 0;
      margin: auto;
    }

    .profile-pic-container{
        display: block;
        margin: 50px auto;
        .profile-pic{
            width: 230px !important;
            height: 230px !important;
        }
        .profile-pic::before{
            content: '';
            position: absolute;
            inset: -20px 80px;
            background: linear-gradient(315deg,#00ccff,#d400d4);
            transition: 0.5s;
            animation: animate 4s linear infinite;
        }
        .profile-pic:hover::before{
            inset: -20px 0px;
        }
        .mobile-socialmedia{
            display: flex;
            margin-top: 15px;

            .abs{
                display: flex;
                align-items: center;
                justify-content: space-around;
                width: 100%;
            }
        }

    }
  }



  