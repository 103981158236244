.skills{
    color: #fff;
    font-weight: 700;
    font-size: 12px;
  }
  h4{
    color: #ffd700;
    font-size: 15px;
    font-weight: 800;
    margin-bottom: 5px;
  }

  a{
    color: #ffd700;
    font-weight: 600;
  }

.skill-set {
    display: flex;
    justify-content: center;
    margin: 200px 0px 0px 600px;
  }
  .set{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .face {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    border: 2px solid transparent;
    border-radius: 50%;
    margin: 10px;
    cursor: pointer;
  }
  .iconi{
    transition: 0.5s ease-out;
  }
  .iconi:hover{
    width: 110px;
    height: 110px;
  }
  
  .face svg {
    width: 50px;
    height: 50px;
  }


  @media screen and (max-width: 1200px) {
    .text-zone{
      bottom: 0;
    }
    .skill-set{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      top: 0;

      .face{
        width: 100%
      }
    }
  }