.contact-form{
    width: 100%;
    margin-top: 20px;

    ul{
        padding: 0;
        margin: 0;


        li{
            padding: 0;
            margin: 0;
            list-style: none;
            margin-bottom: 10px;
            overflow: hidden;
            display: block;
            position: relative;
            opacity: 0;
            animation: fadeInUp 2s 2s;
            animation-fill-mode: forwards;
            clear: both;
        }

        li.half{
            width: 49%;
            margin-left: 2%;
            float: left;
            clear: none;

            &:first-child{
                margin-left: 0;
            }
        }
    }
    input[type = 'text'],
    input[type='email'] {
        width: 100%;
        border: 0;
        background: #115173;
        height: 50px;
        font-size: 16px;
        color: #fff;
        padding: 0 20px;
        box-sizing: border-box;
    }

    textarea{
        width: 100%;
        border: 0;
        background: #115173;
        height: 50px;
        font-size: 16px;
        color: #fff;
        padding: 20px;
        min-height: 150px;
        box-sizing: border-box;
    }

    .flat-button{
        color: #ffd700;
        font-size: 11px;
        letter-spacing: 3px;
        text-decoration: none;
        padding: 8px 10px;
        border: 1px solid #ffd700;
        float: left;
        border-radius: 4px;
        background: 0 0;
        text-transform: uppercase;
        float: right;
        text-align: center;
        margin-right: 10px;
    }
}

.info-map{
    position: absolute;
    background-color: #000;
    top: 100px;
    right: 30%;
    z-index: 999999;
    width: 267px;
    padding: 20px;
    color: #fff;
    font-family: 'Helvetica';
    font-size: 17px;
    font-weight: 300;
    opacity: 0;
    animation: fadeIn 1s 1.5s;
    animation-fill-mode: forwards;

    span{
        font-size: 16px;
        display: block;
        padding-top: 20px;
        color: #ffd700;
    }
}

.map-wrap{
    background: rgba(8,253,216,0.1);
    float: right;
    width: 53%;
    height: 100%;
}

.leaflet-container{
    width: 100%;
    height: 100%;
    position: relative;
    opacity: 0;
    animation: backInRight 1s 1.2s;
    animation-fill-mode: forwards;
}

@media screen and (max-width: 1200px) {
    .map-wrap {
      float: none;
      margin: 0;
      width: 100%;
      height: 300px;
    }

    .info-map{
        position: absolute;
        margin-top: 380px;
        width: 250px;
        height: 100px;
    }
    
  }