.portfolio-page{
    padding-left: 100px;
    padding-right: 50px;
    width: calc(100% - 150px);
    position: initial;
    height: 100%;
    overflow: auto;

    h1.page-title{
        margin-left: 50px;
        margin-top: 80px;
    }
    .p-tag{
        display: flex;
        align-items: flex-start;
        margin-left: 50px;
        margin-top: 15px;
        margin-bottom: 15px;

        .flat-m-button{
                color: #ffd700;
                font-size: 10px;
                font-weight: 400;
                letter-spacing: 2px;
                font-family: sans-serif;
                text-decoration: none;
                padding: 10px 18px;
                border: 1px solid #ffd700;
                margin-left: 25px;
                float: left;
                animation: fadeIn 1s 1.8s backwards;
                white-space: nowrap;
        
                &:hover{
                    background: #ffd700;
                    color: #333;
                }
        }

    }
    .images-container{
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
    }

    .image-box{
        position: relative;
        flex: 1 1 20%;
        height: 320px;
        overflow: hidden;
        border-radius: 2px;
        max-width: calc(25% - 20px);

        .portfolio-image{
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .content-p{
            position: absolute;
            width: 100%;
            z-index: 3;
            padding: 10px 10px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            background: linear-gradient(
                180deg,
                rgba(0,0,0,0.2) 0, rgba(0,0,0,1)
            );
            bottom: -70px;
        }

        .title{
            margin-bottom: 0;
            margin-top: 0;
            color: #fff;
            font-size: 24px;
            font-weight: 700;
            line-height: 24px;
        }

        .description{
            font-size: 11px;
            margin-bottom: 5px;
            padding-right: 20px;
            font-weight: 700;
            color: #fff;
        }

        .tools {
            color: #fff;
            margin-bottom: 0;
        }
        
        .btn{
            margin-top: 30px;
            margin-bottom: 10px;
            padding: 0 23px;
            height: 40px;
            line-height: 34px;
            border: 2px solid #ffd700;
            border-radius: 4px;
            font-size: 14px;
            color: #fff;
            background: transparent;
            font-weight: 700;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            cursor: pointer;
        }

        .btn:hover{
            transform: translateY(-3px);
            background: #ffd700;
        }

        &:after{
            content: "";
            background: linear-gradient(180deg, #3d3d3d, #000);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            opacity: 0;
        }
        &:hover:after{
            opacity: 1;
        }

        &:hover .content-p{
            bottom: 0;
            background: transparent;
        }
    }

}

@media screen and (max-width: 1200px) {
    .container.portfolio-page{
        width: 100%;
        padding: 20px;
        box-sizing: border-box;

        h1.page-title{
            margin-left: 20px;
            margin-top: 20px;
        }

        .images-container{
            display: flexbox;
            flex-wrap: wrap;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
        }
        

        .image-box{
            flex: 1 1 50%;
            height: 200px;
            width: calc(50% - 10px);
        }
        .description{
            display: none;
        }
        
    }
}
