.more-container{
    margin-top: 50px;
    margin-left: 150px;

    .head-h2{
        color: #ffd700;
    }
    .dsn-logo{
        width: 100px;
    }
    p{
        margin-top: 0;
    }
    .nody{
        .box-zone{
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        img{
            width: 110px;
            transition: 0.5s ease-out;
            border: 2px solid #fff;

            &:hover{
                border-color: #ffd700;
            }
        }
    }
}

.logo-box {
    display: flex;

}

.img-tag{
    width: 10%;
    aspect-ratio: 3/2;
    object-fit: contain;
    transition: 0.5s ease-in-out;
    background: black;
    border: 2px solid #fff;
    transition: 0.5s ease-out;
    &:hover{
        border-color: #ffd700;
    }
}


@media screen and (max-width : 1200px) {
    .more-container{
        width: 100%;
        margin: 0 20px 0 10px;
        
        
    }
}
